.sph1 {
  text-align: center;
  margin-top: 2em;
}

.sptable,
.sptr,
.spth,
.sptd {
  border: unset;
  width: unset;
  margin: unset;
  padding: unset;
}

.sptable {
  width: 100%;
  margin-top: 5em;
}

#formulabuilder {
  margin: 2em 5em;
}

.spbutton {
  margin-left: 3em;
  padding: 0.75em 1.5em;
  background-color: green;
  border-radius: 1em;
}

.spbutton:hover {
  background-color: lightgreen;
  #clipboard {
    filter: invert(0);
  }
}

.successmsg {
  margin: 0.5em auto;
  text-align: center;
  font-weight: 800;
  font-size: x-large;
}

.successbox {
  background-color: green;
  padding: 1em;
  /* width: fit-content; */
}

#numbertextbox {
  width: 1;
}

.spselect,
.spinput {
  margin-left: 1em;
}

.option {
  padding-bottom: 0.25em;
}

#clipboard {
  width: 20px;
  filter: invert(1);
}

.sptextarea {
  color: black;
  width: 100%;
  height: inherit;
  background-color: lightgray;
  font-family: monospace;
}
