span{
    font-weight: bold;
}

.clickable{
    cursor: pointer;
}

table{
    float: left;
}