.Home {
  text-align: center;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

table, th, td{
  border:1px solid black;
  width:50%;
  border-collapse:collapse;
  font-size: 18px;
}
.clickable{
  cursor: pointer;
}
thead{
  font-weight: bold;
  font-size:26;
  background-color: ivory;
  cursor: default;
}
